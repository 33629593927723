var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("더 많은 아이디어 살펴보기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("모든 생활 공간에 대한 아이디어. 이것이 현대 생활을 즐기는 방법입니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "justify-lg": "start"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "main-design-card",
      attrs: {
        "flat": "",
        "max-width": "266",
        "rounded": "xs",
        "to": "/"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "v-card__name tit tit--sm font-tertiary white--text"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }