<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/trends/visual.webp" :breadcrumbs="breadcrumbs" title="Ideas & Trends" />

        <page-section class="py-40px py-md-80px">
            <v-container>
                <div class="mt-50 mt-md-100px">
                    <v-row class="row--lg">
                        <v-col cols="12" md="8">
                            <div class="tit mb-12px mb-md-16px">욕실이 트렌디한 오아시스가 됩니다!</div>
                            <p class="txt txt--sm">최신 트렌드, 멋진 아이디어, 가장 인기 있는 욕실 스타일 - 욕실을 나만의 개성 있는 공간으로 바꾸고 일상 생활을 스타일리시하게 만드는 방법을 보여 드리겠습니다. 새로운 꿈의 욕실로 가는 길에 Villeroy & Boch의 창의적인 공간과 색상 컨셉에서 영감을 얻으세요. 지금 트렌드를 발견하고 가장 핫한 스타일을 찾아보세요!</p>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px mb-40px mb-md-80px">
            <v-container>
                <v-row>
                    <v-col cols="12" md="3" class="border-md-r">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">모든 취향에 맞는 욕실 스타일</div>
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            스타일 살펴보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="3" class="border-md-r">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">욕실의 색상 트렌드</div>
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            색상 살펴보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="3" class="border-md-r">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">모든 스타일에 맞는 제품</div>
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            욕실용품을 만나보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">더 많은 아이디어</div>
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            영감을 찾아보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--xxl">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/trends/trend-1.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="txt txt--xs mb-20px grey--text font-weight-light">2024년 트렌드</div>
                        <div class="tit mb-12px mb-md-16px">현재 욕실 트렌드: 올해의 필수 아이템</div>
                        <p class="txt txt--sm mb-18px mb-md-24px">2024년에는 욕실이 현대적인 디자인과 편안함을 상징하게 될 것입니다. 이러한 발전을 반영하는 10가지 트렌드를 소개합니다.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs" href="/" target="_blank">
                            지금 트렌드를 알아보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">최고의 욕실 스타일</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">모든 취향에 맞는 영감</div>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in inspirations">
                        <v-col :key="item" cols="6" md="3">
                            <v-card tile flat :href="item.link" target="_blank">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="txt grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">{{ item.title }} 욕실 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import PlannerSlides from "@/components/client/ideas/bathroom/planner/planner-slides.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        PlannerSlides,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/planner",
            },
        ],
        inspirations: [
            {
                title: "스칸디",
                text: "기분 좋은 욕실을 위한 북유럽 디자인",
                image: "/images/ideas/bathroom/trends/inspiration-1.webp",
                link: "/",
            },
            {
                title: "산업적",
                text: "당신이 꿈꾸는 욕실을 위한 거친 매력.",
                image: "/images/ideas/bathroom/trends/inspiration-2.webp",
                link: "/",
            },
            {
                title: "지중해",
                text: "당신만의 4개의 벽에서 행복한 휴일을 보내세요.",
                image: "/images/ideas/bathroom/trends/inspiration-3.webp",
                link: "/",
            },
            {
                title: "재팬디",
                text: "젠 스타일이 욕실에서 미니멀리즘을 만났습니다.",
                image: "/images/ideas/bathroom/trends/inspiration-4.webp",
                link: "/",
            },
            {
                title: "미니멀리스트",
                text: "욕실을 위한 순수한 우아함.",
                image: "/images/ideas/bathroom/trends/inspiration-5.webp",
                link: "/",
            },
            {
                title: "보헤미안",
                text: "개인적인 오아시스를 위한 자유로운 영혼.",
                image: "/images/ideas/bathroom/trends/inspiration-6.webp",
                link: "/",
            },
            {
                title: "휘게",
                text: "당신의 집을 위한 순수한 아늑함.",
                image: "/images/ideas/bathroom/trends/inspiration-7.webp",
                link: "/",
            },
            {
                title: "별장",
                text: "웰빙의 오아시스를 위한 소박한 우아함.",
                image: "/images/ideas/bathroom/trends/inspiration-8.webp",
                link: "/",
            },
            {
                title: "우디",
                text: "자연스러운 따뜻함과 스타일이 결합되었습니다.",
                image: "/images/ideas/bathroom/trends/inspiration-9.webp",
                link: "/",
            },
            {
                title: "빈티지",
                text: "욕실을 위한 향수 어린 매력과 시대를 초월한 우아함.",
                image: "/images/ideas/bathroom/trends/inspiration-10.webp",
                link: "/",
            },
            {
                title: "아라비안",
                text: "아라비안나이트에 나올 법한 이국적인 패턴과 컬러",
                image: "/images/ideas/bathroom/trends/inspiration-11.webp",
                link: "/",
            },
            {
                title: "럭셔리",
                text: "고품질 소재와 순수한 우아함의 만남",
                image: "/images/ideas/bathroom/trends/inspiration-12.webp",
                link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-expansion-panels {
    .v-expansion-panel {
        &::before {
            box-shadow: none;
        }
        &-header {
            padding: 18px 12px 18px 0;
        }
        &-content {
            ::v-deep(.v-expansion-panel-content__wrap) {
                padding-left: 0;
                padding-right: 56px;
            }
        }
    }
}
.function-content-list {
    list-style: disc;
    padding-left: 20px;
    > li {
        line-height: 1.4;
    }
}
@media (min-width: 768px) {
    .v-expansion-panels {
        .v-expansion-panel {
            &-header {
                padding: 24px 16px 24px 0;
            }
        }
    }
}
</style>