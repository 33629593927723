var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.wide ? _c('section', {
    staticClass: "section-visual"
  }, [_vm.image ? [_c('v-img', {
    attrs: {
      "src": _vm.image
    }
  }, [_c('v-container', {
    staticClass: "container--image"
  }, [_c('v-sheet', {
    staticClass: "section-visual__text",
    attrs: {
      "color": "white"
    }
  }, [_c('div', [_c('v-breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#333"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }], null, false, 1409474385)
  }), _c('h2', {
    staticClass: "tit tit--lg font-tertiary font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)])], 1)], 1)] : [_c('v-sheet', {
    staticClass: "section-visual__sheet",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-container', [_c('v-breadcrumbs', {
    staticClass: "px-0",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#333"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }], null, false, 1409474385)
  }), _c('h2', {
    staticClass: "tit tit--lg tit--center font-tertiary font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)], 1)]], 2) : _c('section', {
    staticClass: "section-visual section-visual--wide"
  }, [_c('v-img', {
    staticClass: "d-flex align-end",
    attrs: {
      "src": _vm.image
    }
  }, [_c('v-sheet', {
    staticClass: "d-inline-flex px-12px pt-16px px-md-80px pt-md-32px",
    attrs: {
      "color": "white"
    }
  }, [_c('div', [_c('v-breadcrumbs', {
    staticClass: "pa-0 line-height-2",
    attrs: {
      "items": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "#333"
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }])
  }), _c('h2', {
    staticClass: "tit tit--lg font-tertiary font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }