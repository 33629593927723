<template>
    <page-section>
        <v-container class="position-relative pb-12px py-md-72px">
            <swiper v-bind="{ options }">
                <template v-for="item in revolutions">
                    <swiper-slide :key="item">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" md="5" order="2" order-md="1">
                                <v-card color="white" class="slide-text">
                                    <div>
                                        <div class="tit mb-12px mb-md-16px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm mb-18px mb-md-24px">
                                            {{ item.text, }}
                                        </p>
                                        <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :href="item.link" target="_blank">
                                            Planungsvorlage starten
                                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="7" order="1" order-md="2">
                                <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                    <v-responsive :aspect-ratio="674 / 504" />
                                    <div class="swiper-controler">
                                        <v-row align="center" class="row--xs">
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="swiper-pagination" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </swiper-slide>
                </template>
            </swiper>
        </v-container>
    </page-section>
</template>

<script>
import pageSection from "@/components/client/templates/page-section.vue";

export default {
    components: { pageSection },
    data: () => ({
        revolutions: [
            {
                title: "샤워 시설을 갖춘 작은 욕실 계획하기",
                text: "Villeroy & Boch의 혁신적인 장비 구성 요소를 사용하면 작은 욕실도 세련되게 꾸며진 웰빙의 오아시스로 탈바꿈할 수 있습니다. 예를 들어, 레인 샤워기가 장착된 바닥에서 천장까지 이어지는 코너 샤워실에서 독특하고 편안한 웰니스 순간을 경험할 수 있습니다. 컴팩트한 변기와 공간 절약형 세면대 캐비닛이 결합된 좁은 세면대는 작은 공간에서 더욱 편안하고 충분한 이동의 자유를 보장합니다. 3D 계획은 꿈의 욕실에 대한 시각적 아이디어를 얻는 데 도움이 됩니다.",
                image: "/images/ideas/bathroom/planner/slide-1.webp",
                link: "/",
            },
            {
                title: "욕조가 있는 작은 욕실 계획하기",
                text: "작은 욕실을 계획할 때 욕실의 편의 시설을 놓치고 싶지 않을 것입니다. 특히 욕조는 더욱 그렇습니다. 빌레로이앤보흐의 공간 절약형 욕조를 사용하면 가장 작은 공간에서도 최대한의 편안함을 누릴 수 있습니다. 싱크대, 변기, 찬장, 수건 걸이, 난방 시설 등 기타 중요한 가구 요소를 위한 공간도 충분합니다.",
                image: "/images/ideas/bathroom/planner/slide-2.webp",
                link: "/",
            },
            {
                title: "웰빙 목욕을 계획해보세요",
                text: "개별적으로 디자인된 웰니스 욕실을 계획할 때 창의력을 자유롭게 발휘할 수 있습니다. 부드럽게 거품이 나는 실내 월풀, 레인 샤워기가 포함된 매립형 웰니스 샤워기, 우아한 세면대, 고품질 설비, 세련된 액세서리에서 영감을 받아 나만의 꿈의 욕실을 하나씩 계획해 보세요.",
                image: "/images/ideas/bathroom/planner/slide-3.webp",
                link: "",
            },
            {
                title: "손님용 욕실 계획하기",
                text: "손님용 욕실을 계획하는 것은 어려운 일입니다. 특히 일반적으로 크기가 작기 때문에, 특히 귀하의 아이디어가 단순한 손님용 화장실을 넘어서는 경우에는 더욱 그렇습니다. 이를 최대한 활용하려면 욕실을 꾸밀 때 실용적인 소형 변기나 좁은 세면대를 선택하세요. 손님을 위한 시설이 완비된 욕실은 공간에 최적화된 대형 샤워실로 완성됩니다.",
                image: "/images/ideas/bathroom/planner/slide-4.webp",
                link: "",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
::v-deep(.swiper-container) {
    overflow: visible;
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
@media (min-width: 768px) {
    .slide-text {
        height: 504px;
        display: flex;
        align-items: center;
        // overflow: hidden;
    }
}
</style>