var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', {
    staticClass: "position-relative pb-12px py-md-72px"
  }, [_c('swiper', _vm._b({}, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.revolutions, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "5",
        "order": "2",
        "order-md": "1"
      }
    }, [_c('v-card', {
      staticClass: "slide-text",
      attrs: {
        "color": "white"
      }
    }, [_c('div', [_c('div', {
      staticClass: "tit mb-12px mb-md-16px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm mb-18px mb-md-24px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('v-btn', {
      staticClass: "rounded-xs ml-1",
      attrs: {
        "large": "",
        "color": "#fff",
        "elevation": "2",
        "href": item.link,
        "target": "_blank"
      }
    }, [_vm._v(" Planungsvorlage starten "), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)])], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "7",
        "order": "1",
        "order-md": "2"
      }
    }, [_c('v-card', {
      staticClass: "position-relative mb-50px mb-md-0",
      attrs: {
        "tile": "",
        "img": item.image
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 674 / 504
      }
    }), _c('div', {
      staticClass: "swiper-controler"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--prev",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-left.svg"
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "swiper-pagination"
    })]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "swiper-button swiper-button--next",
      attrs: {
        "x-small": "",
        "icon": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "32",
        "src": "/images/icon/icon-swiper-right.svg"
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }